import "lazysizes";
import "./animation";
import "./magnatic";

import Splitting from "splitting";

const faqItems = document.querySelectorAll(".faq__card");
console.log("faq items", faqItems);
faqItems.forEach((card) => {
  const closeCta = card.querySelector(".faq__card-close");

  card.addEventListener("click", (e) => {
    if (e.currentTarget.classList.contains("faq__card--open")) {
      e.currentTarget.classList.remove("faq__card--open");
    } else {
      faqItems.forEach((c) => {
        c.classList.remove("faq__card--open");
      });
      card.classList.add("faq__card--open");
    }
  });
});

function countUp(element, endValue, duration) {
  let startValue = 0;
  let startTime = null;

  function animation(currentTime) {
    if (!startTime) startTime = currentTime;
    const progress = Math.min((currentTime - startTime) / duration, 1);
    element.textContent = Math.floor(progress * (endValue - startValue) + startValue);
    if (progress < 1) {
      requestAnimationFrame(animation);
    }
  }

  requestAnimationFrame(animation);
}

function createObserver() {
  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target;
        const endValue = parseInt(target.getAttribute("data-count"), 10);
        countUp(target, endValue, 2000); // 2000ms für die Animation
        observer.unobserve(target); // Stoppt die Beobachtung nach der Animation
      }
    });
  });

  document.querySelectorAll("[data-count]").forEach((element) => {
    observer.observe(element);
  });
}

// Initialisieren des Observers beim Laden der Seite
document.addEventListener("DOMContentLoaded", createObserver);

console.log("splitting");

Splitting({
  /* target: String selector, Element, Array of Elements, or NodeList */
  target: "[data-splitting]",
  /* by: String of the plugin name */
  by: "chars",
  /* key: Optional String to prefix the CSS variables */
  key: null,
});
