import anime from "animejs/lib/anime.es.js";

function introAnimation() {
  const headings = document.querySelectorAll("[data-intro]");
  const letters = "abcdefghijklmnopqrstuvwxyz";
  const allLetters = document.querySelectorAll("[data-char]");

  console.log("introAnimation");

  headings.forEach((heading) => {
    const name = heading.querySelector(".intro__service-item");
    if (name == null) return;
    console.log("name", name);
    let interval = null;

    heading.onmouseenter = (event) => {
      if (heading.dataset.isAnimating === "true") {
        return;
      }

      heading.dataset.isAnimating = "true";
      let iteration = 0;

      clearInterval(interval);

      interval = setInterval(() => {
        name.innerText = name.innerText
          .split("")
          .map((letter, index) => {
            if (index < iteration) {
              return name.dataset.value[index];
            }

            return letters[Math.floor(Math.random() * 26)];
          })
          .join("");

        if (iteration >= name.dataset.value.length) {
          clearInterval(interval);
          heading.dataset.isAnimating = "false";
        }

        iteration += 1 / 3;
      }, 30);
    };
  });

  anime({
    targets: allLetters,
    translateY: [100, 0],
    opacity: [0, 1],
    duration: 1000,
    easing: "easeOutExpo",
    delay: anime.stagger(50),
    complete: (anim) => {
      console.log("complete");
      ani_1.play();
      ani_2.play();
    },
  });

  const ani_1 = anime({
    targets: [".intro__lead-in", ".job-hint", ".header__logo"],
    easing: "linear",
    autoplay: false,
    opacity: [0, 1],
    duration: 400,
  });

  const ani_2 = anime({
    targets: ".about",
    easing: "linear",
    autoplay: false,
    translateY: [100, 0],
    duration: 400,
  });
}

document.addEventListener("DOMContentLoaded", function () {
  document.body.classList.add("show");
  introAnimation();
});

const logo = document.querySelector(".header__logo");

logo.addEventListener("click", (e) => {
  e.preventDefault();
  anime({
    targets: logo,
    keyframes: [{ rotate: 360 }],
    duration: 800,
    easing: "easeOutExpo",
  });
});

const contactBox = document.querySelector(".contact-teaser__contact-wrapper");
const ioFadeOptions = {
  threshold: 0.5,
  rootMargin: "0px 0px -10px 0px",
};

const ioFadeIn = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      entry.target.classList.remove("fade-in");
    } else {
      entry.target.classList.add("fade-in");
    }
  });
}, ioFadeOptions);

ioFadeIn.observe(contactBox);
